import { StepLabel } from '@material-ui/core'
import { Container, Step, Stepper } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import {
  PersonInterface,
  courseStateInterface,
} from '../../interfaces/interfaces'
import { getAllCourses } from '../../providers/courseInfo'
import Category from './sections/Category'
import Confirm from './sections/Confirm'
import ContactData from './sections/ContactData'
import PersonalData from './sections/PersonalData'
import SelectCourse from './sections/SelectCourse'

const BookForm = () => {
  const [step, setStep] = useState(1)
  const [categories, setCategories] = useState({
    katAM: false,
    katA1: false,
    katA2: false,
    katA: false,
    katB1: false,
    katB: false,
    katBE: false,
    katC: false,
    katCE: false,
    katD1: false,
    katD: false,
    kwalifikacja: false,
    wozki: false,
    koparki: false,
    adr: false,
    hds: false,
    ins: false,
  })
  const [person, setPerson] = useState<PersonInterface>({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    emailAdress: '',
  })
  const [birthDate, setBirthDate] = useState<Date | null>(null)

  const [courses, setCourses] = useState<courseStateInterface>({
    courses: [],
    loaded: false,
  })

  useEffect(() => {
    getAllCourses().then((c) => {
      setCourses({
        courses: c,
        loaded: true,
      })
    })
  }, [])

  return (
    <Container style={{ marginTop: 40 }}>
      <Stepper alternativeLabel activeStep={step - 1}>
        <Step>
          <StepLabel>Wybór kategorii</StepLabel>
        </Step>
        <Step>
          <StepLabel>Wybór kursu</StepLabel>
        </Step>
        <Step>
          <StepLabel>Twoje dane</StepLabel>
        </Step>
        <Step>
          <StepLabel>Dane kontaktowe</StepLabel>
        </Step>
        <Step>
          <StepLabel>Podsumowanie</StepLabel>
        </Step>
      </Stepper>
      {step === 1 && (
        <Category
          setStep={setStep}
          nextStep={2}
          categories={categories}
          setCategories={setCategories}
        />
      )}
      {step === 2 && (
        <SelectCourse
          setStep={setStep}
          prevStep={1}
          nextStep={3}
          categories={categories}
          courses={courses}
          setCourses={setCourses}
        />
      )}
      {step === 3 && (
        <PersonalData
          setStep={setStep}
          prevStep={2}
          nextStep={4}
          person={person}
          setPerson={setPerson}
          birthDate={birthDate}
          setBirthDate={setBirthDate}
        />
      )}
      {step === 4 && (
        <ContactData
          setStep={setStep}
          prevStep={3}
          nextStep={5}
          person={person}
          setPerson={setPerson}
        />
      )}
      {step === 5 && (
        <Confirm
          setStep={setStep}
          prevStep={4}
          categories={categories}
          person={person}
          courses={courses.courses}
          birthDate={birthDate}
        />
      )}
    </Container>
  )
}

export default BookForm

import { CategoryKey, PlaceKey } from './../interfaces/interfaces';
import { categoriesWebId, placesWebId } from './../data/categories';
import axios from 'axios'
import { courseInterface } from '../interfaces/interfaces';
import { v4 as uuidv4 } from 'uuid';

export const getAllCourses = async () => {
    const allCourses:courseInterface[] = []
    const {data} = await axios.get('https://nova-europa.pl/wp-json/wp/v2/terminy')
    
    data.forEach((element: any): any => {
        const DateWork = (element.acf.szkolenie_data as string).split('.')
        const CourseDate = new Date(`${DateWork[2]}-${DateWork[1]}-${DateWork[0]} ${element.acf.szkolenie_godzina}`.replace(/-/g, "/"))
        
        // return only future courses
        CourseDate > new Date() && (allCourses.push({
            date: CourseDate,
            categoryKeys: getCategoryKeys(element.kategoria_pj),
            description: element.acf.szkolenie_text || null,
            locationKeys: getLocationKeys(element.miejsce),
            courseID: uuidv4()
        }))
    })

    return allCourses
}

const getCategoryKeys = (categoryIdArray: Number[]):CategoryKey[] => {
    const categoryKeys:CategoryKey[] = []
    categoryIdArray.forEach(id => {
        categoryKeys.push(categoriesWebId.filter(category => category.id === id)[0].key)
    })

    return categoryKeys
}

const getLocationKeys = (placeIdArray: Number[]):PlaceKey[] => {
    const locationKeys: PlaceKey[] = []

    placeIdArray.forEach(id => {
        locationKeys.push(placesWebId.filter(place => place.id === id)[0].key)
    })

    return locationKeys
}


export const playGround = () => {
    getAllCourses()
}
import { Button, TextField, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import { PersonInterface } from '../../../interfaces/interfaces'

type ContactDataProps = {
  person: PersonInterface
  setPerson: Function
  setStep: Function
  nextStep?: number
  prevStep?: number
}

const ContactData = ({
  setStep,
  nextStep,
  prevStep,
  person,
  setPerson,
}: ContactDataProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPerson({ ...person, [event.target.name]: event.target.value })
  }

  const onPhoneChange = (
    value: string,
    data: any,
    event: React.ChangeEvent<HTMLInputElement>,
    formattedValue: string
  ) => {
    setPerson({ ...person, phoneNumber: formattedValue })
  }

  const [emailExit, setEmailExit] = useState(false)
  const [phoneExit, setPhoneExit] = useState(false)

  return (
    <>
      <Typography variant="h5">Dane kontaktowe</Typography>
      <form noValidate autoComplete="off">
        <div>
          <PhoneInput
            country={'pl'}
            onlyCountries={['pl', 'ua', 'de']}
            value={person.phoneNumber}
            onChange={onPhoneChange}
            localization={{ Poland: 'Polska' }}
            masks={{ pl: '... ... ...' }}
            placeholder="+48 123 123 123"
            specialLabel="Numer telefonu"
            inputProps={{
              name: 'phoneNumber',
              autoFocus: true,
            }}
            onBlur={() => setPhoneExit(true)}
            isValid={!(phoneExit && person.phoneNumber.length < 15)}
          />

          <TextField
            error={
              !/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(
                person.emailAdress
              ) && emailExit === true
                ? true
                : false
            }
            label="Adres email"
            variant="outlined"
            onChange={handleChange}
            name="emailAdress"
            type="email"
            value={person.emailAdress}
            onBlur={() => setEmailExit(true)}
          />
        </div>
      </form>
      <Button variant="contained" onClick={() => setStep(prevStep)}>
        WRÓĆ
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setStep(nextStep)}
        disabled={
          person.emailAdress.length === 0 || person.phoneNumber.length !== 15
        }
      >
        DALEJ
      </Button>
    </>
  )
}

export default ContactData

import { PlaceKey } from './../interfaces/interfaces';
import { categoriesDescription, placesDescription } from './../data/categories';
import moment from 'moment';
import 'moment/locale/pl'

moment.locale('pl')

export const printBirthDate = (date: Date) => moment(date).format('DD MMMM YYYY');

export const printCourseDate = (date: Date) => moment(date).format('DD MMMM (dddd)');

export const printCourseTime = (date: Date) => moment(date).format('HH:mm');

export const printCourseLocation = (locationKey: PlaceKey): string => 
    placesDescription.filter(place => place.key === locationKey)[0].name


export const getCardWidth = (items: number) => {
    if (items > 6) return 12
    else if (items > 1) return 6
    else return 3
}

export const getCategoryName = (categoryKey: string) => categoriesDescription.filter(categoryDesc => categoryDesc.key === categoryKey)[0].name

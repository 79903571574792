import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  List,
  ListItem,
  ListItemText,
  Switch,
  Typography,
} from '@material-ui/core'
import React, { useState } from 'react'
import {
  courseInterface,
  courseStateInterface,
} from '../../../interfaces/interfaces'
import {
  getCategoryName,
  printCourseDate,
  printCourseLocation,
  printCourseTime,
} from '../../../utils/utils'
import styled from 'styled-components'
import DoneIcon from '@material-ui/icons/Done'

const CardStyled = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

interface SelectCourseProps {
  courses: courseStateInterface
  setCourses: Function
  categories: Object | any
  setStep: Function
  nextStep?: number
  prevStep?: number
}

const SelectCourse = ({
  setStep,
  nextStep,
  prevStep,
  categories,
  courses,
  setCourses,
}: SelectCourseProps) => {
  const [showNotMatchingCourses, setShowNotMatchingCourses] = useState<boolean>(
    false
  )

  const handleChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowNotMatchingCourses(event.target.checked)
  }

  let matchingCourses: courseInterface[] = []
  let notMatchingCourses: courseInterface[] = []

  const selectedCategories = Object.keys(categories).filter(
    (c) => categories[c]
  )

  courses.courses.forEach((course) => {
    // All matching courses
    course.categoryKeys.forEach((categoryKey) => {
      const isCourseMatching = selectedCategories.some(
        (el) => el === categoryKey
      )

      const isCourseUsed = [...matchingCourses, ...notMatchingCourses]
        .map((c) => c.courseID)
        .includes(course.courseID)

      if (isCourseMatching && !isCourseUsed) matchingCourses.push(course)
    })

    // Rest courses
    course.categoryKeys.forEach((categoryKey) => {
      const isCourseMatching = selectedCategories.some(
        (el) => el === categoryKey
      )

      const isCourseUsed = [...matchingCourses, ...notMatchingCourses]
        .map((c) => c.courseID)
        .includes(course.courseID)

      if (!isCourseMatching && !isCourseUsed) notMatchingCourses.push(course)
    })
  })

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    let courseArr = courses.courses
    let idx = courseArr.findIndex((course) => course.courseID === e.target.name)
    courseArr[idx].selected = checked
    setCourses({ ...courses, courses: courseArr })
  }

  return (
    <>
      {matchingCourses.length > 0 ? (
        <>
          <Typography variant="h5">
            Dostępne kursy dla wybranych kategorii:
          </Typography>

          <CoursesSection
            coursesArray={matchingCourses}
            handleChange={handleChange}
          />
        </>
      ) : (
        <Box p={3} my={5} border="1px solid black" borderRadius={20}>
          Aktualnie nie ma dostępnych kursów dla wybranych kategorii.
        </Box>
      )}

      {notMatchingCourses.length > 0 && (
        <>
          {matchingCourses.length !== 0 && (
            <FormGroup row>
              <FormControlLabel
                control={
                  <Switch
                    checked={showNotMatchingCourses}
                    onChange={handleChangeSwitch}
                    name="checkedB"
                    color="primary"
                  />
                }
                label="Pokaż pozostałe kursy"
              />
            </FormGroup>
          )}
          {(showNotMatchingCourses || matchingCourses.length === 0) && (
            <>
              <Typography variant="h5">
                {matchingCourses.length === 0
                  ? 'Brak kursów ble ble'
                  : 'Pozostałe dostępne kursy:'}
              </Typography>
              <CoursesSection
                coursesArray={notMatchingCourses}
                handleChange={handleChange}
              />
            </>
          )}
        </>
      )}

      <Button variant="contained" onClick={() => setStep(prevStep)}>
        WRÓĆ
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setStep(nextStep)}
        disabled={courses.courses.filter((c) => c.selected).length < 1}
      >
        DALEJ
      </Button>
    </>
  )
}

interface CoursesSectionInterface {
  coursesArray: courseInterface[]
  handleChange?: any
}

const CoursesSection = ({
  coursesArray,
  handleChange,
}: CoursesSectionInterface) => (
  <Grid container spacing={3} alignItems="stretch">
    {coursesArray.map((course, idx) => (
      <Grid item xs={12} md={4} key={idx}>
        <CardStyled>
          <CardContent>
            <Typography variant="overline" display="block" gutterBottom>
              Kategorie
            </Typography>
            <List>
              {course.categoryKeys.map((key, index) => (
                <ListItem key={index} style={{ marginLeft: 0, padding: 0 }}>
                  <DoneIcon style={{ marginRight: 10 }} />
                  <ListItemText primary={getCategoryName(key)} key={index} />
                </ListItem>
              ))}
            </List>
            <Typography variant="overline" display="block" gutterBottom>
              Lokalizacja:
            </Typography>
            <Typography variant="body1">
              {course.locationKeys
                .map((key) => printCourseLocation(key))
                .join(', ')}
            </Typography>
            <Typography variant="overline" display="block" gutterBottom>
              Data i godzina
            </Typography>
            <Typography variant="body1">
              {printCourseDate(course.date)}, godz.{' '}
              {printCourseTime(course.date)}
            </Typography>
            <Typography variant="body2"></Typography>
            {course.description && (
              <Typography variant="body2">{course.description}</Typography>
            )}
          </CardContent>
          {handleChange && (
            <CardActionArea>
              <CardActions>
                <FormControlLabel
                  style={{ width: '100%' }}
                  control={
                    <Checkbox
                      onChange={handleChange}
                      name={course.courseID}
                      checked={course.selected ? true : false}
                    />
                  }
                  label="Wybieram ten kurs"
                />
              </CardActions>
            </CardActionArea>
          )}
        </CardStyled>
      </Grid>
    ))}
  </Grid>
)

export default SelectCourse

import { placesWebIdInterface } from './../interfaces/interfaces';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar'
import MotorcycleIcon from '@material-ui/icons/Motorcycle'
import LocalShippingIcon from '@material-ui/icons/LocalShipping'
import DirectionsBusIcon from '@material-ui/icons/DirectionsBus'
import WhatshotIcon from '@material-ui/icons/Whatshot'
import SettingsIcon from '@material-ui/icons/Settings'
import BuildIcon from '@material-ui/icons/Build'
import PersonIcon from '@material-ui/icons/Person'
import { categoriesWebIdInterface, categoryDescriptionInterface } from '../interfaces/interfaces'




export const categoriesWebId: categoriesWebIdInterface[] = [
    {
        id: 25,
        key: 'katD1'
    },
    {
        id: 24,
        key: 'hds'
    },
    {
        id: 23,
        key: 'certyfikat-kompetencji-zawodowych'
    },
    {
        id: 21,
        key: 'katA1'
    },
    {
        id: 22,
        key: 'katAM'
    },
    {
        id: 20,
        key: 'katA2',
    },
    {
        id: 19,
        key: 'katA'
    },
    {
        id: 18,
        key: 'katB1'
    },
    {
        id: 17,
        key: 'katB',
    },
    {
        id: 16,
        key: 'katBE'
    },
    {
        id: 15,
        key: 'katC'
    },
    {
        id: 14,
        key: "katCE"
    },
    {
        id: 13,
        key: 'katD'
    },
    {
        id: 12,
        key: 'kwalifikacja'
    },
    {
        id: 11,
        key: 'wozki'
    },
    {
        id: 10,
        key: 'koparki'
    },
    {
        id: 9,
        key: "adr"
    },
    {
        id: 8,
        key: 'ins'
    }
]

export const placesWebId: placesWebIdInterface[] = [
    {
        id: 6,
        key: 'mmz'
    },
    {
        id: 7,
        key: 'sul'
    }
]

export const placesDescription = [
    {
        key: 'mmz',
        name: 'Mińsk Mazowiecki'
    },
    {
        key: 'sul',
        name: 'Sulejówek'
    }
]

export const categoriesDescription: categoryDescriptionInterface[] = [
    {
        masterCategory: 'Motocykle',
        key: 'katAM',
        name: 'Kategoria AM',
        description: 'Lorem ipsum',
        icon: MotorcycleIcon
    },
    {
        masterCategory: 'Motocykle',
        key: 'katA1',
        name: 'Kategoria A1',
        description: 'Lorem ipsum',
        icon: MotorcycleIcon
    },
    {
        masterCategory: 'Motocykle',
        key: 'katA2',
        name: 'Kategoria A2',
        description: 'Lorem ipsum',
        icon: MotorcycleIcon
    },
    {
        masterCategory: 'Motocykle',
        key: 'katA',
        name: 'Kategoria A',
        description: 'Lorem ipsum',
        icon: MotorcycleIcon
    },
    {
        masterCategory: 'Samochody osobowe',
        key: 'katB1',
        name: 'Kategoria B1',
        description: 'Lorem ipsum',
        icon: DirectionsCarIcon
    },
    {
        masterCategory: 'Samochody osobowe',
        key: 'katB',
        name: 'Kategoria B',
        description: 'Lorem ipsum',
        icon: DirectionsCarIcon
    },
    {
        masterCategory: 'Samochody osobowe',
        key: 'katBE',
        name: 'Kategoria B+E',
        description: 'Lorem ipsum',
        icon: DirectionsCarIcon
    },
    {
        masterCategory: 'Samochody ciężarowe',
        key: 'katC',
        name: 'Kategoria C',
        description: 'Lorem ipsum',
        icon: LocalShippingIcon
    },
    {
        masterCategory: 'Samochody ciężarowe',
        key: 'katCE',
        name: 'Kategoria C+E',
        description: 'Lorem ipsum',
        icon: LocalShippingIcon
    },
    {
        masterCategory: 'Samochody ciężarowe',
        key: 'certyfikat-kompetencji-zawodowych',
        name: 'Certyfikat Kompetencji Zawodowych',
        description: 'Lorem ipsum',
        icon: LocalShippingIcon
    },
    {
        masterCategory: 'Autobusy',
        key: 'katD1',
        name: 'Kategoria D1',
        description: 'Lorem ipsum',
        icon: DirectionsBusIcon
    },
    {
        masterCategory: 'Autobusy',
        key: 'katD',
        name: 'Kategoria D',
        description: 'Lorem ipsum',
        icon: DirectionsBusIcon
    },
    {
        masterCategory: 'Dla kierowcy zawodowego',
        key: 'kwalifikacja',
        name: 'Kwalifikacja',
        description: 'Lorem ipsum',
        icon: LocalShippingIcon
    },
    {
        masterCategory: 'Dodatkowe uprawnienia',
        key: 'wozki',
        name: 'Wózki widłowe',
        description: 'Lorem ipsum',
        icon: SettingsIcon
    },
    {
        masterCategory: 'Dodatkowe uprawnienia',
        key: 'koparki',
        name: 'Koparko-ładowarki',
        description: 'Lorem ipsum',
        icon: BuildIcon
    },
    {
        masterCategory: 'Dodatkowe uprawnienia',
        key: 'adr',
        name: 'ADR',
        description: 'Lorem ipsum',
        icon: WhatshotIcon
    },
    {
        masterCategory: 'Dodatkowe uprawnienia',
        key: 'hds',
        name: 'HDS',
        description: 'Lorem ipsum',
        icon: LocalShippingIcon
    },
    {
        masterCategory: 'Dla przyszłego instruktora',
        key: 'ins',
        name: 'Kurs instruktora nauki jazdy',
        description: 'Lorem ipsum',
        icon: PersonIcon
    },
]

import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemText,
  Snackbar,
  Typography,
} from '@material-ui/core'
import DoneIcon from '@material-ui/icons/Done'
import Alert from '@material-ui/lab/Alert'
import axios from 'axios'
import React, { useState } from 'react'
import { categoriesDescription } from '../../../data/categories'
import { BACKEND_ENDPOINT } from '../../../data/config'
import {
  courseInterface,
  PersonInterface,
  sendStatusInterface,
} from '../../../interfaces/interfaces'
import {
  getCategoryName,
  printBirthDate,
  printCourseDate,
  printCourseLocation,
  printCourseTime,
} from '../../../utils/utils'
type ConfirmProps = {
  categories: Object | any
  person: PersonInterface
  birthDate: Date | null
  courses: courseInterface[]
  setStep: Function
  nextStep?: number
  prevStep?: number
}

const Confirm = ({
  setStep,
  prevStep,
  categories,
  courses,
  person,
  birthDate,
}: ConfirmProps) => {
  const [sendStatus, setSendStatus] = useState<sendStatusInterface>({
    loading: false,
    messageSent: false,
  })
  const [open, setOpen] = useState(false)

  const selectedCategories = categoriesDescription.filter((c) =>
    Object.keys(categories)
      .filter((categoryKey) => categories[categoryKey])
      .some((cat) => cat === c.key)
  )
  const selectedCourses = courses.filter((course) => course.selected)

  const handleAccept = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setSendStatus({ loading: true, messageSent: false })
    const data = {
      categories: selectedCategories.map((c) => c.name),
      courses: selectedCourses.map((c) => ({
        date: `${printCourseDate(c.date)}, godz. ${printCourseTime(c.date)}`,
        location: c.locationKeys
          .map((key) => printCourseLocation(key))
          .join(', '),
        categories: c.categoryKeys.map((categoryKey) =>
          getCategoryName(categoryKey)
        ),
      })),
      person: {
        ...person,
        birthDate,
      },
    }
    axios
      .post(BACKEND_ENDPOINT, {
        ...data,
      })
      .then(({ data }) => {
        setSendStatus({ loading: false, messageSent: true })
        setOpen(true)
      })
      .catch((err) => {
        //
      })
  }

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  return (
    <>
      <Typography variant="h5">Poprawność danych</Typography>
      <Grid container spacing={4} alignItems="stretch">
        <Grid item md={6} xs={12}>
          <Card>
            <CardHeader title="Dane" />
            <CardContent>
              <Typography variant="overline" display="block" gutterBottom>
                Imię i nazwisko
              </Typography>
              <Typography variant="body2" gutterBottom>
                {person.firstName} {person.lastName}
              </Typography>
              <Typography variant="overline" display="block" gutterBottom>
                Data urodzenia
              </Typography>
              <Typography variant="body2" gutterBottom>
                {birthDate !== null && printBirthDate(birthDate)}
              </Typography>
              <Typography variant="overline" display="block" gutterBottom>
                Numer telefonu
              </Typography>
              <Typography variant="body2" gutterBottom>
                {person.phoneNumber}
              </Typography>
              <Typography variant="overline" display="block" gutterBottom>
                Adres email
              </Typography>
              <Typography variant="body2" gutterBottom>
                {person.emailAdress}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={6} xs={12}>
          <Card>
            <CardHeader title="Wybrane kategorie" />
            <CardContent>
              <List>
                {selectedCategories.map((element, index) => (
                  <ListItem key={index}>
                    <DoneIcon style={{ marginRight: 10 }} />
                    <ListItemText primary={element.name} key={index} />
                  </ListItem>
                ))}
              </List>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Button variant="contained" onClick={() => setStep(prevStep)}>
        WRÓĆ
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={handleAccept}
        disabled={sendStatus.loading || sendStatus.messageSent ? true : false}
      >
        ZAPISZ SIĘ
      </Button>
      {sendStatus.loading && !sendStatus.messageSent && <CircularProgress />}
      <Snackbar open={open} autoHideDuration={10000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Na Twoją skrzynkę wysłaliśmy potwierdzenie zapisu. Do zobaczenia na
          kursie!
        </Alert>
      </Snackbar>
    </>
  )
}

export default Confirm

import {
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Grid,
  Tooltip,
  Typography,
} from '@material-ui/core'
import React from 'react'
import { CategoryInterface } from '../../../interfaces/interfaces'
import { categoriesDescription } from '../../../data/categories'
import { getCardWidth } from '../../../utils/utils'

type CategoryProps = {
  categories: CategoryInterface | any
  setCategories: Function
  setStep: Function
  nextStep?: number
  prevStep?: number
}

const Category = ({
  setStep,
  nextStep,
  categories,
  setCategories,
}: CategoryProps) => {
  const handleChange = (event: React.ChangeEvent | any) => {
    setCategories({ ...categories, [event.target.name]: event.target.checked })
  }

  const masterCategories = categoriesDescription
    .map((category) => category.masterCategory)
    .filter((value, index, self) => self.indexOf(value) === index)

  return (
    <>
      <Typography variant="h5">Wybierz interesującą Cię kategorię</Typography>
      <Typography variant="body1">Możesz wybrać więcej niż jedną</Typography>
      <Grid container spacing={3}>
        {masterCategories.map((mc, idx) => (
          <Grid
            item
            xs={12}
            sm={getCardWidth(
              categoriesDescription.filter((c) => c.masterCategory === mc)
                .length
            )}
            key={idx}
          >
            <Card>
              <CardContent>
                <Typography color="textSecondary" gutterBottom>
                  {mc}
                </Typography>
                {categoriesDescription
                  .filter((c) => c.masterCategory === mc)
                  .map((category, index) => (
                    <Tooltip
                      key={index}
                      title={category.description}
                      style={{ maxWidth: 300 }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={handleChange}
                            icon={<category.icon />}
                            checkedIcon={<category.icon />}
                            name={category.key}
                            checked={categories[category.key] ? true : false}
                          />
                        }
                        label={category.name}
                      />
                    </Tooltip>
                  ))}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setStep(nextStep)}
        disabled={
          Object.keys(categories).filter((k) => categories[k]).length
            ? false
            : true
        }
      >
        DALEJ
      </Button>
    </>
  )
}

export default Category

import DateFnsUtils from '@date-io/date-fns'
import { Button, Typography } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import 'date-fns'
import plLocale from 'date-fns/locale/pl'
import React, { useState } from 'react'
import { PersonInterface } from '../../../interfaces/interfaces'

type PersonalDataProps = {
  person: PersonInterface
  setPerson: Function
  birthDate: Date | null
  setBirthDate: Function
  setStep: Function
  nextStep?: number
  prevStep?: number
}

const PersonalData = ({
  setStep,
  nextStep,
  prevStep,
  person,
  setPerson,
  birthDate,
  setBirthDate,
}: PersonalDataProps) => {
  const [birthDateError, setBirthDateError] = useState<String>('')

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPerson({ ...person, [event.target.name]: event.target.value })
  }

  const handleDateChange = (date: Date | null) => {
    setBirthDate(date)
  }
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={plLocale}>
      <Typography variant="h5">Twoje dane</Typography>
      <form noValidate autoComplete="off">
        <div>
          <TextField
            label="Imię"
            variant="outlined"
            onChange={handleChange}
            name="firstName"
            value={person['firstName']}
            autoFocus
          />
          <TextField
            label="Nazwisko"
            variant="outlined"
            onChange={handleChange}
            name="lastName"
            value={person.lastName}
          />
        </div>
        <KeyboardDatePicker
          format="dd-MM-yyyy"
          margin="normal"
          label="Data urodzenia"
          value={birthDate}
          onChange={handleDateChange}
          invalidDateMessage="Niepoprawna data"
          maxDateMessage="Niepoprawna data"
          placeholder="DD-MM-RRRR"
          onError={(e) => setBirthDateError(String(e))}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          cancelLabel="Anuluj"
        />
      </form>
      <Button variant="contained" onClick={() => setStep(prevStep)}>
        WRÓĆ
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setStep(nextStep)}
        disabled={
          person.firstName.length === 0 ||
          person.lastName.length === 0 ||
          birthDate?.toString.length !== 0 ||
          birthDateError.length !== 0 ||
          birthDate === new Date()
        }
      >
        DALEJ
      </Button>
    </MuiPickersUtilsProvider>
  )
}

export default PersonalData
